@font-face {
  font-family: "archiathin";
  src: url("./assets/fonts/archia/archia-thin-webfont.eot");
  src: url("./assets/fonts/archia/archia-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/archia/archia-thin-webfont.woff2") format("woff2"),
    url("./assets/fonts/archia/archia-thin-webfont.woff") format("woff"),
    url("./assets/fonts/archia/archia-thin-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "archialight";
  src: url("./assets/fonts/archia/archia-light-webfont.eot");
  src: url("./assets/fonts/archia/archia-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/archia/archia-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/archia/archia-light-webfont.woff") format("woff"),
    url("./assets/fonts/archia/archia-light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "archiaregular";
  src: url("./assets/fonts/archia/archia-regular-webfont.eot");
  src: url("./assets/fonts/archia/archia-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/archia/archia-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/archia/archia-regular-webfont.woff") format("woff"),
    url("./assets/fonts/archia/archia-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "archiamedium";
  src: url("./assets/fonts/archia/archia-medium-webfont.eot");
  src: url("./assets/fonts/archia/archia-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/archia/archia-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/archia/archia-medium-webfont.woff") format("woff"),
    url("./assets/fonts/archia/archia-medium-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "archiasemibold";
  src: url("./assets/fonts/archia/archia-semibold-webfont.eot");
  src: url("./assets/fonts/archia/archia-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/archia/archia-semibold-webfont.woff2") format("woff2"),
    url("./assets/fonts/archia/archia-semibold-webfont.woff") format("woff"),
    url("./assets/fonts/archia/archia-semibold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "archiabold";
  src: url("./assets/fonts/archia/archia-bold-webfont.eot");
  src: url("./assets/fonts/archia/archia-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/archia/archia-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/archia/archia-bold-webfont.woff") format("woff"),
    url("./assets/fonts/archia/archia-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  padding: 0;
  margin: 0;
  /* background-color: #181618; */
  background-color: #000;
  position: relative;
}

ul, ol{
  color: #fff;
}